<template>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 form-title editor-title">
                <h2
                    v-if="companyUpdate.id > 0"
                    class="form-title editor-title"
                >
                    {{ $t('companies.edit') }}
                </h2>
                <h2
                    v-else
                    class="form-title editor-title"
                >
                    {{ $t('companies.add') }}
                </h2>
            </div>
            <div class="col-sm-12 form-data">
        <span class="span-title">
          {{ $t('companies.name') }}
        </span>
                <b-form-group
                    class="title"
                    label-for="companyname"
                >
                    <b-input-group>
                        <b-form-input
                            id="companyname"
                            type="text"
                            size="sm"
                            autocomplete="off"
                            v-model="companyUpdate.name"
                            :state="companyUpdate.name !== null && companyUpdate.name.length > 2"
                            @keydown.down="onArrowDown"
                            @keydown.up="onArrowUp"
                            @keydown.enter="onEnter"
                        />
                        <b-input-group-append>
                            <b-button
                                size="sm"
                                variant="info"
                                :disabled="!companyUpdate.name || companyUpdate.name.length < 4"
                                @click="fetchCompanies">{{ $t('common.search') }}</b-button>
                        </b-input-group-append>
                    </b-input-group>
                    <ul
                        ref="scrollContainer"
                        v-show="showCompanies"
                        class="autocomplete-results">
                        <li
                            v-for="(company, i) in companies"
                            ref="options"
                            :key="i"
                            @click="setCompany(company)"
                            :class="{ 'is-active': i === arrowCounter }"
                            class="autocomplete-result">
                            {{ company.name }}
                        </li>
                    </ul>
                </b-form-group>
                <span class="span-title">
          {{ $t('companies.business_id') }}
        </span>
                <b-form-group
                    class="title"
                    label-for="business_id"
                >
                    <b-form-input
                        id="business_id"
                        v-model="companyUpdate.business_id"
                        :state="companyUpdate.business_id !== null && companyUpdate.business_id.length > 7"
                        type="text"
                        size="sm"
                    />
                </b-form-group>
                <span class="span-title">
          {{ $t('companies.vat_id') }}
        </span>
                <b-form-group
                    class="title"
                    label-for="vat_id"
                >
                    <b-form-input
                        id="vat_id"
                        v-model="companyUpdate.vat_id"
                        type="text"
                        size="sm"
                    />
                </b-form-group>
                <span class="span-title">
          {{ $t('areas.location') }}
        </span>
                <b-form-group
                    class="title"
                    label-for="latitude"
                >
                    <b-form-input
                        id="latitude"
                        v-model="companyUpdate.location.y"
                        type="nusmer"
                        step="0.0000000000001"
                        :state="companyUpdate.location.y != null && companyUpdate.location.y >= 0"
                        placeholder="Latitude"
                        size="sm"
                    />
                </b-form-group>
                <b-form-group
                    class="title"
                    label-for="longitude"
                >
                    <b-form-input
                        id="longitude"
                        v-model="companyUpdate.location.x"
                        type="nusmer"
                        step="0.0000000000001"
                        :state="companyUpdate.location.x != null && companyUpdate.location.x >= 0"
                        placeholder="Longitude"
                        size="sm"
                    />
                </b-form-group>
                <h2>
                    {{ $t('companies.address') }}
                </h2>
                <span class="span-title">
                  {{ $t('companies.street') }}
                </span>
                <b-form-group
                    class="title"
                    label-for="street"
                >
                    <b-form-input
                        id="street"
                        v-model="address.street_address"
                        type="text"
                        size="sm"
                    />
                </b-form-group>
                <span class="span-title">
          {{ $t('companies.postal') }}
        </span>
                <b-form-group
                    class="title"
                    label-for="postal"
                >
                    <b-form-input
                        id="postal"
                        v-model="address.postal"
                        type="text"
                        size="sm"
                    />
                </b-form-group>
                <span class="span-title">
          {{ $t('companies.city') }}
        </span>
                <b-form-group
                    class="title"
                    label-for="city"
                >
                    <b-form-input
                        id="city"
                        v-model="address.city"
                        type="text"
                        size="sm"
                    />
                </b-form-group>

                <h2>
                    {{ $t('companies.invoicing_address') }}
                </h2>
                <span class="span-title">
          {{ $t('companies.street') }}
        </span>
                <b-form-group
                    class="title"
                    label-for="invoicing_street"
                >
                    <b-form-input
                        id="invoicing_street"
                        v-model="invoicing_address.street_address"
                        type="text"
                        size="sm"
                    />
                </b-form-group>
                <span class="span-title">
          {{ $t('companies.postal') }}
        </span>
                <b-form-group
                    class="title"
                    label-for="invoicing_postal"
                >
                    <b-form-input
                        id="invoicing_postal"
                        v-model="invoicing_address.postal"
                        type="text"
                        size="sm"
                    />
                </b-form-group>
                <span class="span-title">
          {{ $t('companies.city') }}
        </span>
                <b-form-group
                    class="title"
                    label-for="invoicing_city"
                >
                    <b-form-input
                        id="invoicing_city"
                        v-model="invoicing_address.city"
                        type="text"
                        size="sm"
                    />
                </b-form-group>
                <span class="span-title">
          {{ $t('companies.invoicing_net') }}
        </span>
                <b-form-group
                    class="title"
                    label-for="invoicing_net_operator"
                >
                    <b-form-input
                        id="invoicing_net_operator"
                        v-model="companyUpdate.invoicing_net_operator"
                        type="text"
                        size="sm"
                    />
                </b-form-group>
                <span class="span-title">
          {{ $t('companies.invoicing_code') }}
        </span>
                <b-form-group
                    class="title"
                    label-for="invoicing_net_code"
                >
                    <b-form-input
                        id="invoicing_net_code"
                        v-model="companyUpdate.invoicing_net_code"
                        type="text"
                        size="sm"
                    />
                </b-form-group>

                <div class="col-sm-12 button-container">
                    <b-button
                        variant="danger"
                        class="routa-button"
                        @click.stop="$emit('close')"
                    >
                        <i class="fa fa-times"/>
                        <span class="button-text">
              {{ $t('common.cancel') }}
            </span>
                    </b-button>
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading"
                        @click.stop="submitCompany"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">
              {{ $t('common.save') }}
            </span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {hereService} from '../mixins/HereServiceMixin'

export default {
    name: 'CompanyEditor',
    mixins: [workManagementHelper, restApi, hereService],
    props: {
        company: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            showCompanies: false,
            arrowCounter: -1,
            loading: false,
            companyUpdate: this.newCompany(this.company),
            prhUrl:'https://avoindata.prh.fi/bis/v1?',
            companies: [],
            selectedCompany: null,
            address: {
                street_address: undefined,
                postal: undefined,
                city: undefined
            },
            invoicing_address: {
                street_address: undefined,
                postal: undefined,
                city: undefined
            }
        }
    },
    mounted: function () {
        this.address = (this.companyUpdate.address === undefined) ? this.address : this.companyUpdate.address
        this.invoicing_address = (this.companyUpdate.invoicing_address === undefined) ? this.invoicing_address : this.companyUpdate.invoicing_address
        document.addEventListener('click', this.handleClickOutside);
    },
    destroyed() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        fetchCompanies() {
            if (this.companyUpdate.name) {
                this.clearValues()
                this.restFetchOpenData(this.prhUrl, {name: this.companyUpdate.name}, this.handleDataResponse)
            }
        },
        handleDataResponse:function (response) {
            if (response === null) {
                this.companyUpdate.business_id = null
                this.companyUpdate.vat_id = null
                this.companies = []
            }else{
                this.companies = response.data.results
                this.showCompanies = true;
            }
        },
        getAddressCoordinates: function (address) {
            address = this.addressToString(address)
            this.addressToCoordinates(address, this.handleAddressCoordinatesSuccess)
        },
        handleAddressCoordinatesSuccess: function (response) {
            this.companyUpdate.location = response
        },
        addressToString(address) {
            return address.street_address + " " + address.postal + " " + address.city;
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.arrowCounter = -1;
                this.showCompanies = false;
            }
        },
        onArrowDown() {
            if (this.arrowCounter < this.companies.length-1) {
                this.arrowCounter = this.arrowCounter + 1;
                this.fixScrolling();
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
                this.fixScrolling();
            }
        },
        fixScrolling(){
            if(!this.showCompanies) return
            const liH = this.$refs.options[this.arrowCounter].clientHeight;
            this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
        },
        onEnter() {
            this.companyUpdate.name = this.companies[this.arrowCounter].name;
            this.selectedCompany = this.companies[this.arrowCounter];
            this.arrowCounter = -1;
            this.showCompanies = false;
            this.setCompany(this.selectedCompany)
        },
        setCompany(resultCompany) {
            this.companyUpdate.name = resultCompany.name;
            this.showCompanies = false;
            this.companyUpdate.vat_id = resultCompany.vatId;
            this.companyUpdate.business_id = resultCompany.businessId;
            if (resultCompany.detailsUri) {
                // Force to https if necessary
                if (resultCompany.detailsUri.indexOf('http:') >= 0) {
                    resultCompany.detailsUri = resultCompany.detailsUri.replace('http:', 'https:')
                }
                this.restFetchOpenData(resultCompany.detailsUri, [], this.handleCompanyDetailsResponse);
            } else {
                this.setCompanyValues(resultCompany)
            }
        },
        handleCompanyDetailsResponse(response){
            if(!response){
                this.clearValues()
            }else{
                this.setCompanyValues(response.data.results[0])
            }
        },
        clearValues() {
            this.companyUpdate.address.street_address = '';
            this.companyUpdate.address.postal = '';
            this.companyUpdate.address.city = '';
            this.companyUpdate.invoicing_address.street_address = '';
            this.companyUpdate.invoicing_address.postal = '';
            this.companyUpdate.invoicing_address.city = '';
            this.companyUpdate.location.x = null
            this.companyUpdate.location.y = null
            this.companyUpdate.invoicing_net_operator = null;
            this.companyUpdate.invoicing_net_code = null;
        },
        setCompanyValues (companyData) {
            let invoicingAddress, visitingAddress;
            let addresses = companyData.addresses
            if(addresses.length === 1){
                invoicingAddress = visitingAddress = addresses[0];
            }else if(addresses.length > 1){
                invoicingAddress = addresses.find(item => !item.endDate && item.type === 1)
                visitingAddress = addresses.find(item => !item.endDate && item.type === 2)
            }
            this.companies = []
            this.companyUpdate.name = companyData.name;
            this.companyUpdate.address.street_address = visitingAddress.street;
            this.companyUpdate.address.postal = visitingAddress.postCode;
            this.companyUpdate.address.city = visitingAddress.city;
            this.companyUpdate.invoicing_address.street_address = invoicingAddress.street;
            this.companyUpdate.invoicing_address.postal = invoicingAddress.postCode;
            this.companyUpdate.invoicing_address.city = invoicingAddress.city;
            this.getAddressCoordinates(this.companyUpdate.invoicing_address)
        },
        setDefaultValues: function (companyUpdate) {
            let company = JSON.parse(JSON.stringify(companyUpdate))
            if (company.name === '') {
                EventBus.$emit('show-alert', this.$t('inspectable_editor.invalid_name'))
                return undefined
            }
            if (company.business_id === null || company.business_id === '') {
                EventBus.$emit('show-alert', this.$t('companies.business_id_missing'))
                return undefined
            }
            if (this.address.street_address === undefined && this.address.postal !== undefined && this.address.city === undefined) {
                company.address = undefined
            } else {
                company.address = this.address
            }
            if (this.invoicing_address.street_address === undefined && this.invoicing_address.postal === undefined && this.invoicing_address.city === undefined) {
                company.invoicing_address = undefined
            } else {
                company.invoicing_address = this.invoicing_address
            }
            if(!this.companyUpdate.location || this.companyUpdate.location.x === null || this.companyUpdate.location.x === ""
                || this.companyUpdate.location.y === null || this.companyUpdate.location.y === "") {
                EventBus.$emit('show-alert', this.$t('companies.coordinates_missing'))
                return undefined
            }
            return company
        },

        submitCompany: function () {
            var jsonCompany = this.setDefaultValues(this.companyUpdate)
            if (jsonCompany !== undefined) {
                this.loading = true
                if (this.company.id < 1) {
                    this.restAdd(this.companyUrl, jsonCompany, this.success, this.fail)
                } else {
                    this.restUpdate(this.companyUrl, jsonCompany, this.success, this.fail)
                }
            }
        },

        success: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        }
    }
}
</script>
<style>
.autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 120px;
    min-height: 1em;
    max-height: 6em;
    overflow: auto;
}

.autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
}
.autocomplete-result.is-active,
.autocomplete-result:hover {
    background-color: #4AAE9B;
    color: white;
}
</style>
